import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/calisanlar',
      name: 'calisanlar',
      component: () => import('@/views/Users.vue'),
    },
    {
      path: '/calisangorev',
      name: 'calisangorev',
      component: () => import('@/views/UserQuest.vue'),
    },
    {
      path: '/gorev',
      name: 'gorevTanimla',
      component: () => import('@/views/Quest.vue'),
    },
    {
      path: '/hastane',
      name: 'hastaneTanimla',
      component: () => import('@/views/HospitalCreate.vue'),
    },

    {
      path: '/hospitalDepartment',
      name: 'departmanTanimla',
      component: () => import('@/views/Departman.vue'),
    },
    {
      path: '/departman',
      name: 'hastaneDepartman',
      component: () => import('@/views/hospitalDepartment.vue'),
    },
    {
      path: '/departmanGorev',
      name: 'departmanGorev',
      component: () => import('@/views/DepartmanGorevleri.vue'),
    },
    {
      path: '/treatment',
      name: 'tedaviBolumleri',
      component: () => import('@/views/TreatmentDepartment.vue'),
    },
    {
      path: '/referans',
      name: 'referanceChannel',
      component: () => import('@/views/ReferanceChannel.vue'),
    },
    {
      path: '/iletisim',
      name: 'contactChannel',
      component: () => import('@/views/ContactChannel.vue'),
    },
    {
      path: '/discomfort',
      name: 'discomFort',
      component: () => import('@/views/Discomfort.vue'),
    },
    {
      path: '/hastaTalebi',
      name: 'want',
      component: () => import('@/views/Want.vue'),
    },
    {
      path: '/district',
      name: 'district',
      component: () => import('@/views/District.vue'),
    },
    {
      path: '/province',
      name: 'province',
      component: () => import('@/views/Provinces.vue'),
    },
    {
      path: '/result',
      name: 'result',
      component: () => import('@/views/Result.vue'),
    },
    {
      path: '/patient',
      name: 'patient',
      component: () => import('@/views/Patients.vue'),
    },
    {
      path: '/cagrikayit',
      name: 'cagrikayit',
      component: () => import('@/views/cagrikayit.vue'),
    },
    {
      path: '/cagrikayitlistele',
      name: 'cagrikayitlistele',
      component: () => import('@/views/cagrikayitlistele.vue'),
    },
    {
      path: '/giris',
      name: 'giris',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/misc-not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    if (!isLoggedIn) {

      console.log(window.location.href);
      localStorage.setItem("backPage", window.location.href)
      return next({ name: 'giris' })
    }
    return next({ name: 'misc-not-authorized' })
  }



  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router